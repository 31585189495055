@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");
* {
  font-family: "Rubik", sans-serif;
}
.passenger-detail-card-style-form {
  width: auto;
  box-shadow: 0px 4px 70px rgba(62, 62, 185, 0.08) !important;
  border-radius: 20px !important;
  display: flex;
  flex-direction: column;
  padding: 54px 20px 34px 42px !important;
  gap: 17px;
  height: auto !important;
}
.icon-styles {
  margin: 0px 5px;
}
.heading-style {
  font-weight: 500;
  font-size: 16px !important;
  line-height: 15px;
  color: #000000;
}

.outlined-required {
  background: #ffffff;
  border: none !important;
  border-radius: 10px !important;
  height: 35px;
  margin: 5% 0% !important;
  width: 85%;
}

.warning-text {
  font-size: 10px;
  margin: 0;
}
.form-styles {
  padding: 10px auto;
}
.submit-styles-button {
  margin-top: 3% !important;
  color: #ffffff !important;
  width: 96% !important;
  background: #341ea0 !important;
  border-radius: 12px !important;
}
.css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #000000 !important;
}
.submit-icon-style {
  margin: 0 7px;
}
/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border: 2px solid #4b31ee !important; 
} */
/* .MuiOutlinedInput-root {
  background: #ffffff;
  border-radius: 10px !important;
  height: 40px;
  margin: 5% 0% !important;
  width: 100%;
} */
.textfield-mui {
  background: #ffffff !important;
  border-radius: 10px !important;
  height: 40px !important;
  margin: 5% 0% !important;
  width: 86% !important;
  border: 2px solid #4b31ee !important;
  padding: 0 2% !important;
  align-items: start !important;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
  width: 84% !important;
}
.tel-input {
  background: #ffffff !important;
  border-radius: 10px !important;
  height: 40px !important;
  margin: 5% 2% !important;
  width: 90% !important;
  border: 2px solid #4b31ee !important;
  align-items: start !important;
}
.error-text {
  margin-top: -1% !important;
  color: red !important;
  font-weight: 700 !important;
}
@media screen and (max-width: 600px) {
  .submit-styles-button {
    width: 95% !important;
  }
  .passenger-detail-card-style-form {
    padding-bottom: 5%;
  }
  .MuiOutlinedInput-root {
    width: 119% !important;
  }
  .tel-input {
    width: 90% !important;
  }
}
@media screen and (max-width: 900px) {
  .passenger-detail-card-style-form {
    padding-bottom: 8% !important;
  }
}
.css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
  border: none !important;
}
