@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");
* {
  font-family: "Rubik", sans-serif;
}
.card-styles {
  width: auto;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 70px rgba(62, 62, 185, 0.08) !important;
  border-radius: 20px !important;
  padding: 54px 20px 10px 20px !important;
  display: flex;
  flex-direction: row;
  margin-bottom: 3%;
}
.button-box-style {
  height: auto !important;
}
.stack-icon {
  margin-right: 8px !important;
  width: 18px !important;
  height: 18px !important;
}
.summary-fixed-height-box {
  display: flex;
  justify-content: flex-start;
}
.list-button-style {
  padding-right: 0 !important;
  width: 120% !important;
}
.list-button-style-seats {
  padding-right: 0 !important;
  width: 150% !important;
}
.list-button-style-seats:hover {
  background-color: transparent !important;
  cursor: pointer;
}
.list-button-style:hover {
  background-color: transparent !important;
}
.box-styles {
  height: 20px !important;
  background: #f4f5fe;
  border-radius: 20px;
  padding: 10px 10px 10px 10px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #4b31ee;
  margin: 0 5px;
  vertical-align: middle;
}
.box-styles.passengers {
  width: 33%;
  font-weight: 700 !important;
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
  margin-right: 6%;
}
.box-styles.suitcase {
  width: 33%;
  font-weight: 700 !important;
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
}

.icon-styles {
  width: 22px !important;
}
.text-styles {
  font-size: 12px !important;
  vertical-align: middle;
}
.list-view-styles {
  padding: 1% 7%;
}
.list-spacing {
  padding: 0% !important;
  margin: 0 !important;
}
.list-spacing-style {
  padding: 0% !important;
  margin: 0 !important;
  vertical-align: top;
}
.list-item-class-style span {
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 16px !important;
  color: #404040 !important;
}
.price-box {
  background: #f4f5fe;
  border-radius: 18px;
  padding: 18px;
  text-align: center;
}

.trip-detail-style {
  margin-top: 10%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #5f5f5f;
}

.trip-price-style {
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #4b31ee;
}

.trip-tax-style {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #676767;
  font-style: italic;
}

.grid-style-comp {
  margin: 0 !important;
  padding-top: 20px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

@media screen and (max-width: 900px) {
  .box-styles.passengers {
    width: 40%;
    margin: 0 !important;
    margin-right: 5px !important;
  }
  .trip-tax-style {
    font-size: 14px;
  }
  .box-styles.suitcase {
    width: 40%;
    margin: 0 !important;
  }
  .summary-fixed-height-box {
    height: 40px;
  }
  .list-item-class-style span {
    font-size: 12px !important;
  }
  .grid-style-comp {
    background: #f4f5fe;
    margin: 20px;
    padding-left: 20px;
  }
  .price-box {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-styles {
    padding-bottom: 5% !important;
  }
}
@media screen and (max-width: 600px) {
  .card-styles {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 415px) {
  .box-styles.passengers {
    width: 40% !important;
  }
  .box-styles.suitcase {
    width: 40% !important;
  }
}
