@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");
* {
  font-family: "Rubik" !important;
}
.main-card-style {
  /* position: absolute; */
  width: auto !important;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 70px rgba(62, 62, 185, 0.08);
  border-radius: 20px;
  padding: 20px 15px 0px 15px;
}
.stepper-styles {
  font-family: "Rubik", sans-serif !important;
}
.button-styles {
  margin: 5% 4% 0% 4% !important;
  width: 100%;
  height: 30px;
  font-size: 12px !important;
  background-color: #341ea0 !important;
  border-radius: 12px !important;
  color: #ffffff !important;
}
.edit-trip {
  justify-content: space-evenly;
  margin: 10px 0;
}
.header-style {
  font-size: 18px !important;
}
.p-text-style {
  font-size: 13px !important;
  color: #341ea0;
  font-weight: 600;
}
.box-styles {
  background: #f4f5fe !important;
  border-radius: 25px !important;
  text-align: center !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 16px;
  color: #4b31ee !important;
}
.box-styles.passengerss {
  width: 119px !important;
  height: 32px !important;
  font-size: 10px !important;
  font-weight: 700 !important;
}
.icon-styles {
  width: 18px;
  height: 29px;
}
.box-styles.onewayy {
  width: 95px !important;
  height: 32px !important;
  font-size: 10px !important;
  font-weight: 700 !important;
}
.svg-icon {
  margin: 12px 16px;
}
.step-text-styles {
  font-size: 12px !important;
}
.text-style {
  font-size: 8px !important;
  margin: 0% 5%;
  /* vertical-align: middle; */
}
.button-container {
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0;
}
.change-mind-styles {
  font-size: 12px !important;
  padding-top: 20px;
  color: #341ea0 !important;
  font-weight: 900 !important;
}
.edit-button {
  width: 110px;
  background: #5b5b5b !important;
  color: white !important;
  margin: 10px 0 10px 10px !important;
}
