@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");
* {
  font-family: "Rubik", sans-serif;
}
.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  margin-top: 5%;
}
.app-bar-footer {
  background-color: #222030 !important;
  padding: 4% 8% !important;
}
.grid-style-icon {
  display: flex !important;
  justify-content: center;
}
.follow-us {
  margin: 0% 5% !important ;
}
.follow-us-trip {
  width: 35px !important;
  height: 22px !important;
}
.follow-us-title{
  margin: 0 auto !important;
  display: flex !important;
  align-items: center !important;
justify-content: center;
}
.footer-grid {
  display: flex !important;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 600px) {
  .ssl-style {
    width: 100px !important;
  }
  .grid-follow-us{
    margin-top: 5% !important;
  }
  .footer-grid {
    flex-direction: column !important;
    align-items: center !important;
  }
  .app-bar-footer {

    padding: 6% 8% !important;
  }
  .follow-us{
    padding: 0 !important;
  }
}
