@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");
* {
  font-family: "Rubik", sans-serif;
}
.app-bar {
  background-color: white !important;
  padding: 2% 8% !important;
}
.phone-num {
  color: black;
  font-weight: 700 !important;
  font-size: 18px !important;
  text-decoration: none !important;
}
.grid-style-initial{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

}
.phone-num-svg {
  margin-top: 2%;
  width: 18px !important;
  height: 18px !important;
}
.paris-svg {
  width: 120px !important;
  height: 70px !important;
}
.support-styles {
  color: black !important;
  font-size: 18px !important;
}

@media screen and (max-width: 900px) {
  .grid-style {
    display:flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .phone-num {
    color: black;
    font-weight: 700 !important;
    font-size: 14px !important;
    text-decoration: none !important;
  }
}
@media screen and (max-width: 600px) {
  .phone-num {
    color: black;
    font-weight: 700 !important;
    font-size: 13px !important;
    text-decoration: none !important;
  }
  .paris-svg{
    width: 150px !important;  
    height: 90px !important;  
  }
}
