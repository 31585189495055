.booking-detail-card-style {
  height: auto !important;
  background: #ffffff;
  box-shadow: 0px 4px 70px rgba(62, 62, 185, 0.08) !important;
  border-radius: 20px !important;
  padding: 40px !important;
}
.health-icon-styles {
  margin-top: 10px;
  margin-right: 10px;
  height: 21px;
  width: 29px;
}
.free-seats-styles-summary {
  width: 96%;
  border: 1px solid #969696;
  border-radius: 10px;
  height: 60px;
  vertical-align: center;
  margin: 10px;
}
.heading-style-booking {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;  
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 15px;
  color: #4b31ee;
}
.booking-ref{
  color: #000000 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.sub-heading-style {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 15px;
  color: #000000;
}

.data-heading {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #000000;
}
.data-heading-item {
  color: #686868;
  font-size: 12px !important;
}

.summary-button-submit {
  background: #341ea0 !important;
  border-radius: 12px !important;
  color: white !important;
  height: 45px !important;
  width: 100%;
  border: none !important;
  cursor: pointer;
}
.seats-text-styles {
  font-size: 12px !important;
}
@media screen and (max-width: 600px ){
  .health-icon-styles {
    margin-top: 0px;
    margin-right: 0px;
  }
}
