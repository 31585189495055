@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");
* {
  font-family: "Rubik", sans-serif;
}
.modal-styles {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3%;
  height: auto;
  border-radius: 15px !important;
  background: url("../../assets/modal-back.png") no-repeat center center;
  background-size: cover;
}
.modal-button{
  margin: 7% 0% !important;
  padding: 5% 2%;
  width: 50%;
  height: 30px;
  font-size: 12px !important;
  border: 2px solid white !important;
  border-radius: 12px !important;
  color: #ffffff !important;
}
.close-icon {
  float: right;
  margin: 15px 10px 0 10px;
}
.modal-icon-corner {
  bottom: 0;
}
.modal-heading {
  color: #ffffff;
  font-size: 16px !important;
  font-weight: 900 !important;
}
.modal-content {
  color: #ffffff;
  font-size: 12px !important;
}
