
.step-class-back{
  height: 120px !important;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
/* .stepper-class-style{
  box-shadow: 0px 4px 70px rgba(62, 62, 185, 0.08) !important;
} */
@media screen and (min-width: 600px) {
  .stepper-class-style{
    width: 38% !important;
  }
 
}
@media screen and (max-width: 600px) {
 
.step-class-back{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
}
